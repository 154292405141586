import jwtDecode from 'jwt-decode';

export const currentState = (state) => {
    return state.isAuthenticated
}

export const username = (state) => {
    return state.user?.name || ''
}

export const getUser = (state) => {
    return state.user
}

export const getRole = (state) => {
    if(state.userRole){
        return state.userRole
    }
    const token = sessionStorage.getItem('idToken');

    if (token) {
        const decoded = jwtDecode(token);
        return decoded.roles[0]
    } else {
        return ''
    }

}

export const getUserId = (state) => {
    if(state.userId){
        return state.userId
    }
    const token = sessionStorage.getItem('idToken');

    if (token) {
        const decoded = jwtDecode(token);
        return decoded.id
    } else {
        return ''
    }

}