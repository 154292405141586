import store from '../store';

export const isAuthenticatedGuard =  (to, from, next) => {
    if (store.state.auth && store.state.auth.idToken && store.state.auth.isAuthenticated ||
        (store.state.auth.user && store.state.auth.user.idToken && store.state.auth.user.isAuthenticated )) {
        next();
    } else {
        next({ name: 'Login' });
    }
}
export const isSuperAdmin = () => {
    return store.getters["auth/getRole"] === 'ROLE_SUPER_ADMIN';
}

export const isCongregation= () => {
    return store.getters["auth/getRole"] === 'ROLE_CONGREGATION';
}

export const isEmployee = () => {
    return store.getters["auth/getRole"] === 'ROLE_EMPLOYEE';
}


export const conditionalNext = function(condition) {
    return (to, from, next) => {
        if (condition) {
            next();
            return
        }
        next({ name: 'Login' });
    }
}

