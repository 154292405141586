import { createStore } from "vuex";
import auth from "./auth";
import user from "./user";
import theme from "./theme";
import mutualist from "./mutualist";


const store = createStore({
  modules: {
    auth,
    mutualist,
    user,
    theme,
  }
});

store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  sessionStorage.setItem('store', JSON.stringify(state.auth));
});

export default store;
