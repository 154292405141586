<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <div>
              <h3 class="card-title text-primary mb-0">Nuevo usuario</h3>
              <div class="sub-line"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="col-sm mx-60">
              <user-form :entity="newUser" action="create" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import UserForm from "./UserForm";


    const newUser = ref({
      email: "",
      firstname: "",
      lastname: "",
      password: "",
      cif: "",
      profile: "",
      active: true
    });

</script>
