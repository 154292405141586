<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h6>Cambiar contraseña</h6>
          </div>
          <div class="card-body" v-if="currentUser">
            <div class="col-sm mx-60">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="row">
                    <div class="col-md-6">
                      <label for="new_password" class="form-control-label">
                        Contraseña nueva
                      </label>
                      <input
                          required
                          v-model="currentUser.newPassword"
                          id="new_password"
                          class="form-control"
                          type="text"
                          placeholder=""
                      />
                      <p v-if="v.newPassword.$error" class="invalid-feedback mb-0">
                        <small class="d-block" v-for="error in v.newPassword.$errors" :key="error.$message">
                          {{ error.$message }}
                        </small>
                      </p>
                      <p v-if="passwordError" class="invalid-feedback mb-0">
                        <small class="d-block">
                          {{ passwordError }}
                        </small>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label for="repeat_password" class="form-control-label">
                        Repetir Contraseña nueva
                      </label>
                      <input
                          required
                          v-model="currentUser.newRetypedPassword"
                          id="repeat_password"
                          class="form-control"
                          type="text"
                          placeholder=""
                      />
                      <p v-if="v.newRetypedPassword.$error" class="invalid-feedback mb-0">
                        <small class="d-block" v-for="error in v.newRetypedPassword.$errors" :key="error.$message">
                          {{ error.$message }}
                        </small>
                      </p>
                      <p v-if="passwordRetypeError" class="invalid-feedback mb-0">
                        <small class="d-block">
                          {{ passwordRetypeError }}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                      type="submit"
                      class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      border border-primary
                      rounded-lg
                      hover:bg-blue-900
                    "
                  >
                    Cambiar contraseña
                  </button>
                  <button
                      class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                      @click="handleCancel"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import Swal from "sweetalert2";
import {helpers, minLength} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";


const store = useStore();
const router = useRouter();
const route = useRoute();
const passwordError = ref(null)
const passwordRetypeError = ref(null)
const getCurrentUser = async (id) => {
  return await store.dispatch("user/getCurrentUser", id);
};
const rules = computed(() => {
  return {
    newPassword: {
      minLength: helpers.withMessage("Debe tener al menos 10 caracteres", minLength(10)),
    },
    newRetypedPassword: {
      minLength: helpers.withMessage("Debe tener al menos 10 caracteres", minLength(10)),
    }
  };
})

onMounted(getCurrentUser(route.params.id));

const currentUser = computed(() => store.getters["user/getCurrentUser"]);
const errorMessage = computed(() => store.getters["user/getErrorMessage"]);
const v$ = useVuelidate(rules.value, currentUser);
const v = v$.value;
const handleSubmit = () => {
  passwordRetypeError.value = null
  passwordError.value = null
  if(currentUser.value.newRetypedPassword !== currentUser.value.newPassword){
    passwordError.value = "Las contraseñas no coinciden";
    return;
  }
  let pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).+$/;
  const isMatchPassword = pattern.test(currentUser.value.newPassword);
  const isMatchRepeatedPassword = pattern.test(currentUser.value.newRetypedPassword);
  if(!isMatchRepeatedPassword){
    passwordRetypeError.value = "Las contraseñas deben contener caracteres especiales, letras y números"
  }
  if(!isMatchPassword){
    passwordError.value = "Las contraseñas deben contener caracteres especiales, letras y números"
  }

  v.$validate();

  if (!v$.value.$invalid) {
    changePassword(currentUser.value, true);
  }

};

const changePassword = async (user) => {
  try {
    user.byAdmin = true
    await store.dispatch("user/changePassword", user).then(async res => {
      if (res.status && (res.status === 200 || res.status === 201)) {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Se ha cambiado la contraseña satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        })
        await router.push({name: "Usuarios", params: {}});
      } else {
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Se ha producido un error.',
          showConfirmButton: false,
          timer: 3000
        })
      }
      errorMessage.value = null
    }).catch(async () => {
      await Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Se ha producido un error.',
        showConfirmButton: false,
        timer: 3000
      })
    })

  } catch (error) {
    console.log(error);
  }
};

const handleCancel = () => {
  router.push({ name: "Usuarios" });
};

</script>
<style scoped lang="scss">
.invalid-feedback {
  display: block;
}
</style>