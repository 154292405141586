
export const toggleSidebarColor = ({ commit }, payload) => {
    commit("sidebarType", payload);
}
export const setCardBackground = ({ commit }, payload) => {
    commit("cardBackground", payload);
}
export const navbarMinimize = ({ commit }) => {
    commit("navbarMinimize");
}
export const navbarMinimizeFromMain = ({ commit }) => {
    commit("navbarMinimizeFromMain");
}
