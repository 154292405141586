<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="py-4 container-fluid" v-if="formModel">
              <div class="row">
                <div class="col-12">
                  <div class="card mb-4 mt-5 dd">
                    <div class="card-header pb-0 d-flex justify-content-between">
                      <h6>Cambiar contraseña</h6>
                    </div>
                    <div class="card-body">
                      <div class="col-sm mx-60">
                        <form @submit.prevent="handleSubmit">
                          <div class="row">
                            <div class="row">
                              <div class="col-md-12">
                                <label for="password" class="form-control-label">
                                  Contraseña nueva
                                </label>
                                <input
                                    v-model="formModel.password"
                                    id="password"
                                    class="form-control"
                                    type="password"
                                    placeholder=""
                                />
                                <p v-if="v.password.$error" class="invalid-feedback mb-0">
                                  <small class="d-block" v-for="error in v.password.$errors" :key="error.$message">
                                    {{ error.$message }}
                                  </small>
                                </p>
                                <p v-if="passwordError" class="invalid-feedback mb-0">
                                  <small class="d-block">
                                    {{ passwordError }}
                                  </small>
                                </p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <label for="repeatedPassword" class="form-control-label">
                                  Repetir Contraseña nueva
                                </label>
                                <input
                                    v-model="formModel.repeatedPassword"
                                    id="repeatedPassword"
                                    class="form-control"
                                    type="password"
                                    placeholder=""
                                />
                                <p v-if="v.repeatedPassword.$error" class="invalid-feedback mb-0">
                                  <small class="d-block" v-for="error in v.repeatedPassword.$errors" :key="error.$message">
                                    {{ error.$message }}
                                  </small>
                                </p>
                                <p v-if="passwordRetypeError" class="invalid-feedback mb-0">
                                  <small class="d-block">
                                    {{ passwordRetypeError }}
                                  </small>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <button
                                type="submit"
                                class="float-end
                                        btn btn-primary
                                        text-white
                                        bg-blue-600
                                        border border-primary
                                        rounded-lg
                                        hover:bg-blue-900">
                              Cambiar contraseña
                            </button>
                            <button
                                class="me-2
                                        float-end
                                        btn btn-primary
                                        text-danger
                                        bg-white
                                        border border-danger
                                        rounded-lg
                                        hover:bg-blue-900"
                                @click="handleCancel">
                              Cancelar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import Swal from "sweetalert2";
import {helpers, minLength, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";


const store = useStore();
const router = useRouter();
const route = useRoute();
const token = ref()
const passwordError = ref(null)
const passwordRetypeError = ref(null)
const formModel = ref({
  password: null,
  repeatedPassword: null,
  token: token.value
})

onMounted(()=>{
  token.value = route.params.token
})
const errorMessage = computed(() => store.getters["user/getErrorMessage"]);
const rules = computed(() => {
  return {
    password: {
      required: helpers.withMessage("Este campo es obligatorio", required),
      minLength: helpers.withMessage("Debe tener al menos 10 caracteres", minLength(10)),
    },
    repeatedPassword: {
      required: helpers.withMessage("Este campo es obligatorio", required),
      minLength: helpers.withMessage("Debe tener al menos 10 caracteres", minLength(10)),
    }
  };
})
const v$ = useVuelidate(rules.value, formModel.value);
const v = v$.value;

const handleSubmit = () => {
  passwordRetypeError.value = null
  passwordError.value = null
  formModel.value.token = token.value
  let pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).+$/;
  const isMatchPassword = pattern.test(formModel.value.password);
  const isMatchRepeatedPassword = pattern.test(formModel.value.repeatedPassword);
  if(!isMatchRepeatedPassword){
    passwordRetypeError.value = "Las contraseñas deben contener caracteres especiales, letras y números"
  }
  if(!isMatchPassword){
    passwordError.value = "Las contraseñas deben contener caracteres especiales, letras y números"
  }

  v.$validate();
  if(!isMatchPassword || !isMatchRepeatedPassword){
    return
  }
  if (!v$.value.$invalid) {
    changePassword(formModel.value);
  }

};

const changePassword = async (data) => {
  try {
    await store.dispatch("user/recuperatePassword", data).then(async (res) => {
      if (res.status === 200) {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Su contraseña ha sido cambiada satisfactoriamente',
          showConfirmButton: false,
          timer: 5000
        })
      } else {
        let json = await res.json()
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: json.message,
          showConfirmButton: false,
          timer: 5000
        })
      }
    }).catch((res)=>{
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: res.message,
        showConfirmButton: false,
        timer: 5000
      })
    })
    if (errorMessage.value === null) {
      await router.push({ name: "Dashboard" });
    }else{
      ElMessage.error(errorMessage.value);
    }
  } catch (error) {
    console.log(error);
  }
};

const handleCancel = () => {
  router.push({ name: "Login" });
};

</script>
<style lang="scss" scoped>
.dd{
  margin: auto;
  width: 50%;
  @media(max-width: 700px){
    width: 100%;
  }
}
.invalid-feedback {
  display: block;
}
</style>