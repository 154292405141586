export const loginUser = ( state, { user, idToken, refreshToken }) => {

    if ( idToken ) {
        sessionStorage.setItem( 'idToken', idToken )
        state.idToken = idToken
    }

    if ( refreshToken ) {
        sessionStorage.setItem( 'refreshToken', refreshToken )
        state.refreshToken = refreshToken
    }

    state.user   = user
    state.isAuthenticated = true
    sessionStorage.setItem( 'isAuthenticated', 'true' )
    sessionStorage.setItem( 'user', user.stringify )
}

export const initialiseStore = (state) => {
    // Check if the ID exists
    if(sessionStorage.getItem('store')) {
        // Replace the state object with the stored item
        state.user = JSON.parse(sessionStorage.getItem('store'))
        state.isAuthenticated = JSON.parse(sessionStorage.getItem('store')).isAuthenticated
    }
}

export const setUserRole = ( state, role ) => {
    state.userRole = role
}

export const setUserId = ( state, id ) => {
    state.userId = id
}


export const setUsername = ( state, name ) => {
    state.user.username = name
}
export const setIdToken = ( state, token ) => {
    state.idToken = token
}
export const logout = (state) => {

    state.user = null
    state.idToken = null
    state.isAuthenticated = false

    sessionStorage.removeItem('idToken')
    sessionStorage.removeItem('isAuthenticated')
    sessionStorage.removeItem('user')
    localStorage.removeItem('credentials')
}
