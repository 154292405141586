import axios from '@/services/api';
import jwtDecode from "jwt-decode";
// import jwtDecode from 'jwt-decode';

export const initializeStore = async ({ commit }) => {
    commit('initialiseStore');
}
export const login = async ({ commit }, user) => {
    return await axios.post('/login_check', {
        code: user.code,
        password: user.password
    }).then(response => {
        if(response.data){
            const { data } = response;
            const { token } = data;

            if (token) {
                commit('loginUser', { user, token })

                sessionStorage.setItem('idToken', token);
                commit('setUsername', user.firstname);
                commit('setIdToken', token);
                const decoded = jwtDecode(token);
                let role = decoded.roles[0]
                let id = decoded.id
                commit('setUserRole', role);
                commit('setUserId', id);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }

            return { ok: true }
        }else{
            return { ok: false, message: response.response.data.message };
        }


    }).catch(() => {
        // console.log(parseApiErrors(error.response));
        return { ok: false, message: 'Correo electrónico o contraseña no válidos' };

    })

}

export const getToken = () => {
    return sessionStorage.getItem('idToken');

}

export const logout = ({ commit }) => {
    commit('logout');
}

// export const register = ({ commit }, user) => {
//     return Auth.register(user).then(
//         response => {
//             commit('registerSuccess');
//             return Promise.resolve(response.data);
//         },
//         error => {
//             commit('registerFailure');
//             return Promise.reject(error);
//         }
//     );
// }
