<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div>
              <h3 class="card-title text-primary mb-0">Información de contacto</h3>
              <div class="sub-line"></div>
            </div>
          </div>
          <div class="card-body px-3 pt-0 pb-2">
            <p class="p-3">Si tiene cualquier duda puede ponerse en contacto con nosotros a través de las siguientes personas o mandando un email a la dirección <a href="mailto:salud@umas.es">salud@umas.es</a>:</p>
              <ul>
                <li>Esther Alonso: <i>e.alonso@umas.es</i> o en los teléfonos 91.325.51.03 / 639.488.595</li>
                <li>Carmen Antoranz: <i>c.antoranz@umas.es</i> o en los teléfonos 91.353.52.24 / 682.117.145</li>
                <li>Ysabel Reyes: <i>y.reyes@umas.es</i> o en los teléfonos 91.452.01.12 / 664.261.201</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>

</script>

<style scoped>

</style>
