<template>
  <div v-if="currentUser == null">Loading</div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h6>Editar datos de responsable</h6>
          </div>
          <div class="card-body">
            <div class="col-sm mx-60">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-6">
                    <label for="user_firstname" class="form-control-label">
                      Nombre
                    </label>
                    <input
                      id="user_firstname"
                      v-model="currentUser.firstname"
                      :class="[
                        'form-control',
                        v.firstname.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p v-if="v.firstname.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.firstname.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label for="user_lastname" class="form-control-label">
                      Apellido
                    </label>
                    <input
                      v-model="currentUser.lastname"
                      id="user_lastname"
                      :class="[
                        'form-control',
                        v.lastname.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p v-if="v.lastname.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.lastname.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <label for="user_address" class="form-control-label">
                      Dirección Postal
                    </label>
                    <textarea
                      id="user_address"
                      v-model="currentUser.address"
                      :class="[
                        'form-control',
                        v.address.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                      rows="3"
                    />
                    <p v-if="v.address.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.address.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label for="user_email" class="form-control-label">
                      Email
                    </label>
                    <input
                      id="user_email"
                      :value="currentUser.email"
                      class="form-control"
                      type="text"
                      placeholder=""
                      disabled
                    />
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    type="submit"
                    class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      border border-primary
                      rounded-lg
                      hover:bg-blue-900
                    "
                  >
                    Guardar cambios
                  </button>
                  <button
                    class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";


    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const rules = computed(() => {
      return {
        firstname: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        lastname: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        address: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
      };
    });

    const getCurrentUser = async (id) => {
      return await store.dispatch("user/getCurrentUser", id);
    };

    onMounted(getCurrentUser(route.params.id));

    const currentUser = computed(() => store.getters["user/getCurrentUser"]);

    const v$ = useVuelidate(rules, currentUser);
    const v = v$.value;

    const handleSubmit = () => {
      v$.value.$validate();

      if (!v$.value.$invalid) {
        updateUser(currentUser.value);
      }
    };

    const updateUser = async (user) => {
      try {
        await store.dispatch("user/edit", user);
        await router.push({ name: "Usuarios", params: {} });
      } catch (error) {
        console.log(error);
      }
    };

    const handleCancel = () => {
      router.push({ name: "Departamentos" });
    };

</script>
