<template>
  <form @submit.prevent="handleSubmit" v-if="!loading">
    <div class="row">
      <div class="col-md-6">
        <label for="user_firstname" class="form-control-label">
          Nombre
        </label>
        <input
          id="user_firstname"
          v-model="formModel.firstname"
          :class="[
                        'form-control',
                        v.firstname.$error ? 'is-invalid' : '',
                      ]"
          type="text"
          placeholder=""
        />
        <p v-if="v.firstname.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.firstname.$errors[0].$message }}
          </small>
        </p>
        <label for="user_lastname" class="form-control-label">
          Apellido
        </label>
        <input
          v-model="formModel.lastname"
          id="user_lastname"
          :class="[
                        'form-control',
                        v.lastname.$error ? 'is-invalid' : '',
                      ]"
          type="text"
          placeholder=""
        />
        <p v-if="v.lastname.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.lastname.$errors[0].$message }}
          </small>
        </p>
        <label for="user_cif" class="form-control-label">
          CIF
        </label>
        <input
          id="user_cif"
          v-model="formModel.cif"
          :class="[
                        'form-control',
                        v.cif.$error ? 'is-invalid' : '',
                      ]"
          type="text"
          placeholder=""
        />
        <p v-if="v.cif.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.cif.$errors[0].$message }}
          </small>
        </p>
        <label for="user_active" class="form-control-label w-100">
          Perfil
        </label>
        <el-select
            v-model="formModel.profile"
            size="large"
            class="w-100"
        >
          <el-option value="Administrador" label="Administrador" />
          <el-option value="Congregacion" label="Congregación" />
          <el-option value="Empleado" label="Empleado" />
        </el-select>
      </div>

      <div class="col-md-6">
        <label for="user_code" class="form-control-label">
          Código
        </label>
        <input
          id="user_code"
          v-model="formModel.code"
          :class="[
                        'form-control',
                        v.code.$error ? 'is-invalid' : '',
                      ]"
          type="number"
          placeholder="Código de identificación en CONGREGASEG"
        />
        <p v-if="v.code.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.code.$errors[0].$message }}
          </small>
        </p>
        <label for="user_email" class="form-control-label">
          Email
        </label>
        <input
          id="user_email"
          v-model="formModel.email"
          :class="[
                        'form-control',
                        v.email.$error ? 'is-invalid' : '',
                      ]"
          type="email"
          placeholder=""
        />
        <p v-if="v.email.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.email.$errors[0].$message }}
          </small>
        </p>
        <label for="user_password" class="form-control-label">
          Contraseña
        </label>
        <input
          v-model="formModel.password"
          id="user_password"
          :class="[
                        'form-control',
                        v.password.$error ? 'is-invalid' : '',
                      ]"
          type="text"
          placeholder=""
        />
        <p v-if="v.password.$error" class="invalid-feedback mb-0">
          <small class="d-block" v-for="error in v.password.$errors" :key="error.$message">
            {{ error.$message }}
          </small>
        </p>
        <p v-if="passwordError" class=" mb-0" style="color: #fd5c70;">
          <small class="d-block">
            {{ passwordError }}
          </small>
        </p>
        <div v-if="action !== `update`">
        <label for="user_active" class="form-control-label w-100">
          Activo
        </label>
        <el-select
          v-model="formModel.active"
          size="large"
          class="w-100"
        >
          <el-option :value="true" label="Si" />
          <el-option :value="false" label="No" />
        </el-select>
        </div>
      </div>

      <div class="mt-3">
        <button
          type="submit"
          class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      border border-primary
                      rounded-lg
                      hover:bg-blue-900
                    "
        >
          {{ buttonText }}
        </button>
        <button
          class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
          @click="handleCancel"
        >
          Cancelar
        </button>
      </div>
    </div>
  </form>
  <Loading v-else />
</template>

<script setup>
import { ref, computed, defineProps, onMounted } from "vue";
//import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import {required, helpers, minLength} from "@vuelidate/validators";
import Swal from 'sweetalert2';
import axios from '@/services/api';
import Loading from '@/components/Loading.vue';
//const store = useStore();
const router = useRouter();
const loading  = ref(false)
const props = defineProps({
  entity: {
    type: Object,
    required: false
  },
  action: {
    type: String,
    required: true
  }
})
const buttonText = computed(() => props.action === 'create' ? 'Crear' : 'Guardar cambios');

onMounted(() => {

});

const formModel = ref(props.entity|| {});

const rules = computed(() => {
  return {
    code: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    cif: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    firstname: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    lastname: {
     // required: helpers.withMessage("Este campo es obligatorio", required),
    },
    email: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    profile: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    active: {},
    password: {
      required: helpers.withMessage("Este campo es obligatorio", required),
      minLength: helpers.withMessage("Debe tener al menos 10 caracteres", minLength(10)),
    }
  };
});

const v$ = useVuelidate(rules.value, formModel.value);
const v = v$.value;
const passwordError = ref(null)
const handleSubmit = () => {
  passwordError.value = null
  let pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).+$/;
  const isMatchPassword = pattern.test(formModel.value.password);

  if(!isMatchPassword){
    passwordError.value = "Las contraseñas deben contener caracteres especiales, letras y números"
    return
  }
  v$.value.$validate();

  if (!v$.value.$invalid) {
    formModel.value.code = parseInt(formModel.value.code)
    createEntity(formModel.value);
  }
};

const handleCancel = () => {
  router.push({ name: "Usuarios", params: {} });
};

const createEntity = async (user) => {
  loading.value = true
  await axios.post('users', user ).then((res) => {
    loading.value = false
    if( res.status && (res.status === 200|| res.status === 201)){
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Usuario creado satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      })
    }else{
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: res.response.data.violations[0].message || res.response.data['hydra:description'] || 'Ha ocurrido un error.',
        showConfirmButton: false,
        timer: 3000
      })
    }

  }).catch((res) =>{
    loading.value = false
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: res.response.data.violations[0].message || res.response.data['hydra:description'] || 'Ha ocurrido un error.',
      showConfirmButton: false,
      timer: 3000
    })
  })
}

</script>
