import { createRouter, createWebHistory } from "vue-router";
import UserList from "@/views/user/UserList.vue";
import UserUpdate from "@/views/user/UserUpdate.vue";
import UserCreate from "@/views/user/UserCreate.vue";
import ChangePassword from "@/views/user/ChangePassword.vue";
import ChangeUserPassword from "@/views/user/ChangeUserPassword.vue";
import UserProfile from "@/views/user/UserProfile.vue";
import NoPageFound from "@/views/NoPageFound.vue";
import MutualistList from "../views/mutualists/MutualistList";
import MutualistUnregister from "../views/mutualists/MutualistUnregister";
import MutualistRegister from "../views/mutualists/MutualistRegister";
import Faq from "../views/faq/Faq";
import Contact from "../views/contact/Contact";
import AccessRegistry from "../views/user/AccessRegistry";
import Dashboard from "../views/dashboard/Dashboard";
import {isSuperAdmin, isAuthenticatedGuard} from "../services/authGuard";
import RecuperatePassword from "@/views/user/RecuperatePassword.vue";
import Terms from "@/views/terms/Terms.vue";
const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/LoginUser'),
    },
    {
        path: "/confirm/recuperate-password/:token",
        name: "Recuperar-Contraseña",
        component: RecuperatePassword
    },{
        path: '/',
        name: 'Front',
        component: () => import('@/layouts/MainLayout'),
        children: [
            {
                path: "/",
                name: "Dashboard",
                component: Dashboard,
                beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "/users",
                name: "Usuarios",
                component: UserList,
                beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "users/:id",
                name: "Actualizar-Usuario",
                component: UserUpdate,
                beforeEnter: [isSuperAdmin]
            },
            {
                path: "users/add",
                name: "Crear-Usuario",
                component: UserCreate,
                beforeEnter: [isSuperAdmin]
            },
            {
                path: "users/edit/:id",
                name: "Cambiar-Contraseña",
                component: ChangePassword
            },
            {
                path: "users/change-password/:id",
                name: "Cambiar-Contraseña-Usuario",
                component: ChangeUserPassword
            },
            {
                path: "user/profile/edit/:id",
                name: "Editar-Perfil",
                component: UserProfile,
                beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "/users/access",
                name: "Accesos",
                component: AccessRegistry,
                beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "/mutualists",
                name: "Listado-Mutualistas",
                component: MutualistList,
                beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "/mutualists/register",
                name: "Mutualista-Alta",
                component: MutualistRegister,
                beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "/mutualists/unregister",
                name: "Mutualista-Baja",
                component: MutualistUnregister,
                beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: '/faq',
                name: 'Preguntas-Frecuentes',
                component: Faq,
             //   beforeEnter: [isSuperAdmin]
            },
            {
                path: "/contact",
                name: "Contacto",
                component: Contact,
                beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "/privacy-terms",
                name: "Privacy",
                component: Terms,
                beforeEnter: [isAuthenticatedGuard]
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        component: NoPageFound
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
});

export default router;
