<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <div>
                <h3 class="card-title text-primary mb-0">Registros de acceso</h3>
                <div class="sub-line"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 py-2">
                <el-input
                  clearable
                  type="text"
                  placeholder="Nombre"
                  v-model="searchName"
                />
              </div>
              <div class="col-md-3 py-2">
                <el-input
                  clearable
                  type="text"
                  placeholder="Fecha"
                  v-model="createdAt"
                />
              </div>
              <div class="col-md-3">
                <button
                  @click="handleSearch"
                  class="
                      float-start
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border border-primary
                      hover:bg-blue-900
                    ">
                  Buscar
                </button>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                <tr>
                  <th class="text-uppercase font-weight-bolder">
                    <div class="d-none d-lg-table-cell">Nombre</div>
                    <div class="d-lg-none d-table-cell">Nombre /Email /Perfil /Fecha</div>
                  </th>
                  <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
                    Email
                  </th>
                  <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
                    Perfil
                  </th>
                  <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
                    Fecha
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in store.state.user.accesses" :key="item['id']">
                  <td style="padding-left: 25px;">
                    <div class="d-lg-block d-none">{{ item.user.firstname }}</div>
                    <div class="d-lg-none d-table-block"><b>Nombre: </b>{{ item.user.firstname }}</div>
                    <div class="d-lg-none d-table-block"><b>Email: </b>{{ item.user.email }}</div>
                    <div class="d-lg-none d-table-block"><b>Perfil: </b>{{ item.user.profile }}</div>
                    <div class="d-lg-none d-table-block"><b>Fecha: </b>{{ item.createdAt }}</div>
                  </td>
                  <td class="d-none d-lg-table-cell">
                    {{ item.user.email }}
                  </td>
                  <td class="d-none d-lg-table-cell">
                    {{ item.user.profile }}
                  </td>
                  <td class="d-none d-lg-table-cell">
                    {{ item.createdAt }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div>
                <ul class="pagination">
                  <li class="page-item"><a class="page-link btt" @click.prevent="prevPage" v-if="store.state.user.paginationAccess.page > 1">Anterior</a></li>
                  <li class="page-item"><a class="page-link btt" disabled v-if="!store.state.user.paginationAccess.page || store.state.user.paginationAccess.page == 1">Anterior</a></li>
                  <li class="page-item"> <span class="page-link">{{ store.state.user.paginationAccess.page }} / {{ store.state.user.paginationAccess.last > 1 ? store.state.user.paginationAccess.last -1 : 1  }}</span></li>
                  <li class="page-item"> <a class="page-link btt" @click.prevent="nextPage" v-if="store.state.user.paginationAccess.page != store.state.user.paginationAccess.last">Siguiente</a></li>
                  <li class="page-item"><a class="page-link btt" disabled  v-if="!store.state.user.paginationAccess.page || store.state.user.paginationAccess.page == store.state.user.paginationAccess.last">Siguiente</a></li>
                  <li class="page-item"> <span class="page-link total">Total: {{store.state.user.paginationAccess.totalItems}} </span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const searchName = ref("");
const createdAt = ref("");
onMounted(() => {
  getAccesses();
});

const prevPage = () => {
  store.dispatch("user/getAccesses", {
    page: parseInt(store.state.user.paginationAccess.page) -1
  });
};
const nextPage = () => {
  store.dispatch("user/getAccesses", {
    page: parseInt(store.state.user.paginationAccess.page)+1
  });
};

const handleSearch = () => {
  store.dispatch("user/getAccesses", {
    "user.firstname": searchName.value,
    "createdAt": createdAt.value
  });
};
const getAccesses = async () => {
  return await store.dispatch("user/getAccesses");
};
</script>

<style scoped>

</style>
