
import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/scss/custom.scss";
import "./assets/scss/customElement.scss";
import Theme from "./theme";
import ElementPlus from 'element-plus'
import es from 'element-plus/es/locale/lang/es'
const appInstance = createApp(App);

appInstance.use(store);
appInstance.use(router);

appInstance.use(Theme);
appInstance.use(ElementPlus, {
    locale: es,
});
appInstance.mount("#app");


