<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <div>
                <h3 class="card-title text-primary mb-0">Listado de Usuarios</h3>
                <div class="sub-line"></div>
              </div>
              <div class="col-auto">
                <router-link
                    :to="{ name: 'Crear-Usuario' }"
                    class="
                    btn btn-primary
                    text-white
                    bg-blue-600
                    rounded-lg
                    hover:bg-blue-900
                    mb-0
                  "
                >
                  Nuevo usuario
                </router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 py-2">
                <el-input
                    clearable
                    type="text"
                    placeholder="Código"
                    v-model="code"
                />
              </div>
              <div class="col-md-3">
                <button
                    @click="handleSearch"
                    class="
                      float-start
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border border-primary
                      hover:bg-blue-900
                    ">
                  Buscar
                </button>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="col-12 text-end" style="padding-right: 25px">
              <button class="btn btn-info
                    rounded-lg
                    hover:bg-blue-900"
                    @click="exportList">
               <i class="fa fa-file"></i> Exportar
              </button>
            </div>

            <div class="table-responsive p-0">
              <el-table :data="users" size="default" table-layout="auto" @sort-change="sort">
                <el-table-column prop="code" sortable="custom" label="Código"/>
                <el-table-column prop="cif" sortable="custom" label="CIF"/>
                <el-table-column prop="firstname" sortable="custom" label="Nombre"/>
                <el-table-column prop="email" sortable="custom" label="Email"/>
                <el-table-column prop="profile" sortable="custom" label="Perfil" />
                <el-table-column label="Acciones">
                  <template #default="scope">
                    <router-link
                        class="btn btn-link text-danger text-gradient px-3 mb-0"
                        :to="{
                          name: 'Actualizar-Usuario',
                          params: { id: scope.row['id'] },
                        }"
                    > <i
                        class="fa fa-pencil text-dark me-2"
                        aria-hidden="true"
                    ></i
                    >Editar
                    </router-link>
                    <router-link
                        :to="{
                          name: 'Cambiar-Contraseña-Usuario',
                          params: { id: scope.row['id'] },
                        }"
                    >
                      <a
                          class="btn btn-link text-dark px-3 mb-0"
                          href="javascript:;"
                      >
                        <i
                            class="fa fa-key text-dark me-2"
                            aria-hidden="true"
                        ></i
                        >Cambiar Contraseña
                      </a>
                    </router-link>
                    <a
                        href="javascript:;"
                        @click="handleDelete(scope.row['id'])"
                    >
                      <i class="far fa-trash-alt me-2" aria-hidden="true"></i
                      >Borrar
                    </a>

                  </template>
                </el-table-column>
              </el-table>
              <div>
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link btt" @click.prevent="goToPage(store.state.user.pagination.first)" v-if="store.state.user.pagination.page !== store.state.user.pagination.first">
                      &lt;&lt;
                    </a>
                  </li>

                  <li class="page-item"><a class="page-link btt" @click.prevent="prevPage" v-if="store.state.user.pagination.page > 1">Anterior</a></li>
                  <li class="page-item"><a class="page-link btt" disabled v-if="!store.state.user.pagination.page || store.state.user.pagination.page == 1">Anterior</a></li>
                  <li class="page-item"> <span class="page-link">{{ store.state.user.pagination.page }} / {{ store.state.user.pagination.last > 1 ? store.state.user.pagination.last -1 : 1  }}</span></li>
                  <li class="page-item"> <a class="page-link btt" @click.prevent="nextPage" v-if="store.state.user.pagination.page != store.state.user.pagination.last">Siguiente</a></li>
                  <li class="page-item"><a class="page-link btt" disabled  v-if="!store.state.user.pagination.page || store.state.user.pagination.page == store.state.user.pagination.last">Siguiente</a></li>

                  <li class="page-item">
                    <a class="page-link btt" @click.prevent="goToPage(store.state.user.pagination.last)" v-if="store.state.user.pagination.page !== store.state.user.pagination.last">
                      &gt;&gt;
                    </a>
                  </li>
                  <li class="page-item"> <span class="page-link total">Total: {{store.state.user.pagination.totalItems}} </span></li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { ElMessageBox } from "element-plus";
const store = useStore();
const code = ref("");

onMounted(() => {
  getUsers();
});
const goToPage = (page) => {
  let order = localStorage.getItem('umas_sort')
  let payload = {
    page: page > 1 ? page -1 : page
  }
  if(order){
    payload = JSON.parse(order)
    payload.page =  page > 1 ? page -1 : page
  }
  store.dispatch("user/getUsers", payload);
};
const prevPage = () => {
  let order = localStorage.getItem('umas_sort')
  let payload = {
    page: parseInt(store.state.user.pagination.page) -1
  }
  if(order){
    payload = JSON.parse(order)
    payload.page =  parseInt(store.state.user.pagination.page) -1
  }
  store.dispatch("user/getUsers", payload);
};
const nextPage = () => {
  let order = localStorage.getItem('umas_sort')
  let payload = {
    page: parseInt(store.state.user.pagination.page)+1
  }
  if(order){
    payload = JSON.parse(order)
    payload.page =  parseInt(store.state.user.pagination.page)+1
  }
  store.dispatch("user/getUsers", payload);
};

const handleSearch = () => {
  store.dispatch("user/getUsers", {
    code: code.value
  });
};
const exportList = async () => {
  await store.dispatch('user/exportList') .then(response => {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(response.data);
    a.download = 'usuarios.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
  })
      .catch(error => {
        console.error('Error loading Excel:', error);
      });
}
const users = computed(() => store.state.user.users);

const getUsers = async () => {
  let order = localStorage.getItem('umas_sort')
  return await store.dispatch("user/getUsers", order ? JSON.parse(order) : null);
};
const sort = async (colum) => {
  let payload = {
    prop: colum.prop,
    order: colum.order === 'descending' ? 'DESC' : 'ASC'
  }
  localStorage.setItem('umas_sort', JSON.stringify(payload))
  if(code.value){
    payload.code = code.value
  }
  return await store.dispatch("user/getUsers", payload);
};
const deleteUser = async (user) => {
  try {
    await store.dispatch("user/deleteUser", user);
    await store.dispatch("user/getUsers");
  } catch (error) {
    console.log(error);
  }
};

const handleDelete = (item) => {
  ElMessageBox.confirm("¿Estás seguro que deseas eliminar el usuario?")
      .then(() => {
        deleteUser(item);
      })
      .catch(() => {
        // catch error
      });
};

</script>
