<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <div>
                <h3 class="card-title text-primary mb-0">Listado de Asegurados</h3>
                <div class="sub-line"></div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-3" v-if="!loading">
              <p>Para solicitar su listado de mutualistas debe enviar una petición haciendo click en este botón.</p>
              <p>En un plazo aproximado de 30  minutos, recibirá un email con la relación de asegurados.</p>
                <button @click="handleSubmit"
                    class="
                      float-start
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border border-primary
                      hover:bg-blue-900
                      mt-4
                    ">Solicitar listado</button>
            </div>
            <Loading v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import axios from '@/services/api';
import Swal from 'sweetalert2';
import Loading from "../../components/Loading";

const loading = ref(false)


const handleSubmit = async () => {
  loading.value = true
  await axios.get('/mutualist/petition').then(res => {
    if(res.status && (res.status === 200 || res.status === 201)){
      loading.value = false
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'La solicitud ha sido enviada. En breve recibirá el listado de asociados.',
        showConfirmButton: false,
        timer: 3000
      })
    }else{
      loading.value = false
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Ha ocurrido un error',
        showConfirmButton: false,
        timer: 3000
      })
    }

  }).catch(() =>{
    loading.value = false
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Ha ocurrido un error',
      showConfirmButton: false,
      timer: 3000
    })
  })
};

</script>
