<template>
  <ul>
    <li>Esther Alonso: <i><a href="mailto:e.alonso@umas.es">e.alonso@umas.es</a></i> o en los teléfonos 91.325.51.03 / 639.488.595</li>
    <li>Carmen Antoranz: <i><a href="mailto:c.antoranz@umas.es">c.antoranz@umas.es</a></i> o en los teléfonos 91.353.52.24 / 682.117.145</li>
    <li>Ysabel Reyes: <i><a href="mailto:y.reyes@umas.es">y.reyes@umas.es</a></i> o en los teléfonos 91.452.01.12 / 664.261.201</li>
  </ul>
</template>

<script setup>

</script>

<style scoped>

</style>