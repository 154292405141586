export const setUsers = (state, users) => {
    state.users = users;
}

export const setCurrentUser = (state, user) => {
    state.currentUser = user;
    state.loading = false
}

export const setUser = (state, user) => {
    state.user = user;
    sessionStorage.setItem('user',JSON.stringify(user))
    state.loading = false
}

export const setPagination = (state, pagination) => {
    state.pagination = pagination;
    state.loading = false
}

export const setAccesses = (state, accesses) => {
    state.accesses = accesses;
}

export const setPaginationAccess = (state, pagination) => {
    state.paginationAccess = pagination;
    state.loading = false
}

export const setLoading = (state, loading) => {
    state.loading = loading
}

export const setErrorMessage = (state, errorMessage) => {
    state.errorMessage = errorMessage
}