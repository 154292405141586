import axios from '@/services/api';

export const getUsers = async ({ commit }, params) => {
    let response = null
   if(params && params.prop){
       let query = '?order['+params.prop+']='+ params.order
       delete params.order;
       delete params.prop;
       response = await axios.get('/users'+query, {params: params})
   }else{
       response = await axios.get('/users', { params: params})
   }


    const { data } = response;

    try {
        if (data) {
            commit('setUsers', data["hydra:member"]);
            commit('setPagination', {
                page: data['hydra:view'] ? parseInt(data['hydra:view']['@id'].split('page=')[1]) : 1,
                totalItems: parseInt(data['hydra:totalItems']),
                first: data['hydra:view'] ? parseInt(data['hydra:view']['hydra:first'].split('page=')[1]) : 1,
                last: data['hydra:view'] ? parseInt(data['hydra:view']['hydra:last'].split('page=')[1]) :1,
                next: data['hydra:view'] ? parseInt(data['hydra:view']['hydra:next'].split('page=')[1]) : null
            });
        }

    } catch (e) {
        console.log(e);
    }

};
export const exportList = async () => {
    return axios({
        method: "get",
        url: process.env.VUE_APP_URL + "/api/users/export",
        responseType: "blob",
    });
}
export const getUser = async ({ commit }, id) => {

    commit('setCurrentUser', null)

    await axios.get(`/users/${id}`).then(response => {
        const { data } = response;
        if (data) {
            commit('setUser', data);
        }
    })
}

export const getAccesses = async ({ commit }, params) => {
    commit('setLoading', true)
    const response = await axios.get('/accesses', { params: params})
    const { data } = response;
    try {
        if (data) {
            commit('setAccesses', data["hydra:member"]);
            commit('setPaginationAccess', {
                page: data['hydra:view'] ? parseInt(data['hydra:view']['@id'].split('page=')[1]) : 1,
                totalItems: parseInt(data['hydra:totalItems']),
                first: data['hydra:view'] ? parseInt(data['hydra:view']['hydra:first'].split('page=')[1]) : 1,
                last: data['hydra:view'] ? parseInt(data['hydra:view']['hydra:last'].split('page=')[1]) :1,
                next: data['hydra:view'] ? parseInt(data['hydra:view']['hydra:next'].split('page=')[1]) : null
            });
        }
    } catch (e) {
        console.log(e);
    }
};

export const getCurrentUser = async ({ commit }, id) => {

    commit('setCurrentUser', null)

    await axios.get(`/users/${id}`).then(response => {
        const { data } = response;
        if (data) {
            commit('setCurrentUser', data);
        }
    })
}

export const add = async (_, payload) => {

    return await axios.post("users", payload);
};

export const edit = async (_, payload) => {

    return await axios.put("users/" + payload['id'], payload);
};

export const changePassword = async ({ commit }, payload) => {

    commit('setErrorMessage', null)

   return  await axios.put("users/reset-password/" + payload.id + (payload.byAdmin ? "?byAmin=true" : ''), payload)

};

export const recuperatePassword = async ({ commit }, payload) => {

    commit('setErrorMessage', null)

    return await fetch(process.env.VUE_APP_URL + "/confirm/recuperate-password/" + payload.token , {
        method: 'POST',
        body: JSON.stringify({
            password: payload.password,
            repeatedPassword: payload.repeatedPassword
        })

    });

};

export const deleteUser = async ({ commit }, id) => {

    await axios.delete(`/users/${id}`).then(()=>{
        commit('setLoading', false);
    })
};

export const forgotPassword = async (_, email) => {

    return await fetch(process.env.VUE_APP_URL+"/forgot-password/"+email , {
        method: 'GET'
    });
}