<template>
   <div>
     <div  v-if="activePicture >= 1" class="arrow-icon" @click="activePicture=activePicture - 1">
       <img alt="arrow left" src="@/assets/img/arrow-left.png">
     </div>
       <div
         class="slide-image"
         :style="{'background-image':`url(${images[activePicture].url})`}"
         style="height: 300px;"
       >
         <div class="row text-center">
         <div class="col-12">
            <h3>{{ images[activePicture].text }}</h3>
         </div>
         <div class="col-12 mt-3" v-if="images[activePicture].link">
           <a target="_blank" :href="images[activePicture].link" class="btn btn-primary">Informáte ahora</a>
         </div>
         </div>
       </div>
     <div v-if="activePicture < images.length -1" class="arrow-icon-r" @click="activePicture=activePicture +1">
       <img src="@/assets/img/arrow-right.png" alt="arrow right">
     </div>
   </div>
  <!-- slider dots -->
  <div class="dots">
    <div
      v-for="(p,i) in images"
      :key="i.url"
      :class="['dot',{'active':activePicture === i }]"
      @click="activePicture=i"
    ></div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";

  const activePicture = ref(0)
  const images = ref([
        {
          text: 'UMAS, la aseguradora de referencia para entidades religiosas y de ámbito solidario',
          url: '/img/UMAS01.png',
          link: 'https://www.umas.es/'
        },
        {
          text: 'UMAS Salud. Seguros de salud en condiciones exclusivas',
          url: '/img/UMAS02.png',
          link: null
        },
        {
          text: 'Seguros para residencias',
          url: '/img/UMAS03.png',
          link: 'https://www.umas.es/seguros/multirriesgo/residencias/'
        },
        {
          text: 'Seguros para Centros Docentes',
          url: '/img/UMAS04.png',
          link: 'https://www.umas.es/seguros/multirriesgo/centros-docentes/'
        }
    ])

  onMounted(() => {
    setInterval(function (){
      if(activePicture.value < images.value.length-1){
        activePicture.value = activePicture.value +1
      }else{
        activePicture.value = 0
      }

    }, 5000)
   })

</script>

<style scoped>
 .slide-image{
   display: flex;
   justify-content: center;
   justify-items: center;
   width: 100%;
   height: 300px;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   animation: show 2s;
   border-radius: 4px;
   align-items: center;
 }
 @keyframes show {
   0% { opacity: 0; }
   50% { opacity: 0.5; }
   100% { opacity: 1; }
 }

 #image-container img.hide  {
   animation: hide .5s;
   animation-fill-mode: both;
 }
 .arrow-icon{
   position: absolute;
   left: 50px;
   top: 200px;
   z-index: 10000;
 }
 .arrow-icon-r{
   position: absolute;
   right: 50px;
   top: 200px;
   z-index: 10000;
 }
 h3 {
   color: white;
   text-shadow: -2px 4px 0 rgba(0,0,0,0.3);
   font-size: 2rem;
 }
 .cta {

 }
</style>
