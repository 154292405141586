<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-3">
            <div>
              <h3 class="card-title text-primary mb-0">Preguntas frecuentes</h3>
              <div class="sub-line"></div>
            </div>
          </div>
          <div class="card-body px-3 pt-0 pb-2">
            <h4>¿Qué acciones se pueden realizar en el PORTAL de mutualistas?</h4>
            <p>En este portal se pueden realizar las siguientes acciones:</p>
            <ul>
              <li>Petición de altas en Adeslas, Sanitas y Asisa. Para los igualatorios, hay que descargarse el cuestionario y / o solicitud y enviarlo por email a salud@umas.es</li>
              <li>Petición de baja en cualquier compañía.</li>
              <li>Petición de listado de asegurados actuales.</li>
            </ul>

            <h4>¿Por qué no se pueden dar altas en los IMQ?</h4>
            <p>En el caso de los Igualatorios del País Vasco, Navarra y Asturias no se pueden pedir altas a través de la web, porque es necesario rellenar la solicitud y / o el cuestionario de salud.</p>
            <p>Por eso, a todas las altas nuevas se les aplicarán periodos de carencia y se tendrán en cuenta las enfermedades preexistentes de cara al aseguramiento. Para estas compañías también existe un límite de edad para la contratación de 66 años. Se pueden descargar estos documentos en el portal, en la pestaña de cada compañía y enviarlos a la dirección salud@umas.es</p>

            <h4>¿Se pueden dar altas siempre que se quiera? </h4>
            <p>Sí, las altas se pueden solicitar en cualquier momento del mes, si bien se tardará unas 72 horas en que sean efectivas y el efecto solo podrá ser el día 1 del mes empezado, o del siguiente mes. </p>
            <p>Posteriormente, en un plazo aproximado de 2 semanas, se recibirá la documentación y la tarjeta de la coaseguradora elegida, en la dirección indicada en el formulario de alta.</p>
            <p>En el caso de que la petición de alta sea posterior al día 20 del mes en curso, aunque el alta se dé correctamente a efectos del seguro, el cobro se realizará un mes después. En dicho mes se cobrarán dos mensualidades.</p>
            <p>Si la Institución tiene contratado el Seguro de Salud para todos sus miembros, a las nuevas incorporaciones no se les aplicarán periodos de carencias ni se tendrán en cuenta las enfermedades preexistentes.</p>
            <p>En caso contrario, las nuevas altas deberán rellenar un cuestionario de salud; se tendrán en cuenta las enfermedades preexistentes de cara al aseguramiento y se aplicarán los periodos de carencia establecidos por cada coaseguradora para determinadas intervenciones o pruebas diagnósticas. Los cuestionarios están disponibles en la pestaña de cada compañía.</p>

            <h4>¿Cuándo se pueden pedir las bajas? </h4>
            <p>Se pueden pedir las bajas siempre que se quiera. La baja se producirá con fecha del último día del mes que se ha solicitado. Solo se pueden realizar bajas retroactivas en caso de fallecimiento y para eso es necesario mandar un email a salud@umas.es adjuntando el certificado de defunción. Al igual que en las altas, si esta petición de baja se realiza después del día 20 del mes en curso, aunque la baja se tramite a efectos aseguradores, en el mes siguiente se cobrará la prima, pero le será devuelta dos meses después.</p>
           <!-- <h4>¿Cuánto tiempo tarda en llegarme el listado de asegurados?</h4>
            <p>En un plazo aproximado de media hora les llegará al email de contacto de la Institución el listado de asegurados actuales. Si no fuese así, puede ponerse en contacto con nosotros por email o teléfono</p>
            -->
            <div>
              <p>Si tiene cualquier duda puede consultar nuestra información de contacto y llamarnos por teléfono o escribirnos un <router-link :to="{name:'Contacto'}">email</router-link> explicando la duda que tiene.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>

</style>
