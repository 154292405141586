<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div>
              <h3 class="card-title text-primary mb-0">Solicitud de baja</h3>
              <div class="sub-line"></div>
            </div>
          </div>
          <div class="card-body pb-2">
            <div class="" v-if="!loading">
              <div class="col-sm mx-60">
                <form ref="formRef" @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col-md-6">
                      <p class="text-sm text-danger text-bold">Los campos con * son obligatorios</p>
                      <label for="company" class="form-control-label w-100">
                        *Compañía
                      </label>
                      <el-select
                        v-model="formModel.company"
                        size="large"
                        class="w-100"
                      >
                        <el-option
                          v-for="selectItem in store.state.mutualist.companies"
                          :key="selectItem['@id']"
                          :value="selectItem['@id']"
                          :label="selectItem.name"
                        />
                      </el-select>
                      <label for="name" class="form-control-label">
                        *Nombre
                      </label>
                      <input
                        id="name"
                        v-model="formModel.name"
                        :class="[
                        'form-control',
                        v.name.$error ? 'is-invalid' : '',
                      ]"
                        type="text"
                        placeholder=""
                      />
                      <p v-if="v.name.$error" class="invalid-feedback mb-0">
                        <small>
                          {{ v.name.$errors[0].$message }}
                        </small>
                      </p>
                      <label for="first" class="form-control-label">
                        *Apellido 1
                      </label>
                      <input
                        id="first"
                        v-model="formModel.first"
                        :class="[
                        'form-control',
                        v.first.$error ? 'is-invalid' : '',
                      ]"
                        type="text"
                        placeholder=""
                      />
                      <p v-if="v.first.$error" class="invalid-feedback mb-0">
                        <small>
                          {{ v.first.$errors[0].$message }}
                        </small>
                      </p>
                      <label for="second" class="form-control-label">
                        Apellido 2
                      </label>
                      <input
                        id="second"
                        v-model="formModel.second"
                        :class="[
                        'form-control',
                        v.second.$error ? 'is-invalid' : '',
                      ]"
                        type="text"
                        placeholder=""
                      />
                      <p v-if="v.second.$error" class="invalid-feedback mb-0">
                        <small>
                          {{ v.second.$errors[0].$message }}
                        </small>
                      </p>
                      <label for="motive" class="form-control-label w-100">
                        <span v-if="isAsisa">*</span>Motivo de la baja
                      </label>
                      <el-select
                        v-model="formModel.motive"
                        size="large"
                        class="w-100"
                      >
                        <el-option
                          v-for="selectItem in store.state.mutualist.motives"
                          :key="selectItem['@id']"
                          :value="selectItem['@id']"
                          :label="selectItem.name"
                        />
                      </el-select>
                    </div>
                    <div class="col-md-6">
                      <label for="document" class="form-control-label">
                        *Tipo de documento
                      </label>
                      <el-select
                        v-model="formModel.document"
                        size="large"
                        class="w-100"
                      >
                        <el-option
                          v-for="selectItem in store.state.mutualist.documents"
                          :key="selectItem['@id']"
                          :value="selectItem['@id']"
                          :label="selectItem.name"
                        />
                      </el-select>
                      <label for="number" class="form-control-label">
                        *Número de documento
                      </label>
                      <input
                        id="number"
                        v-model="formModel.number"
                        :class="[
                        'form-control',
                        v.number.$error ? 'is-invalid' : '',
                      ]"
                        type="text"
                        placeholder=""
                      />
                      <p v-if="v.number.$error" class="invalid-feedback mb-0">
                        <small>
                          {{ v.number.$errors[0].$message }}
                        </small>
                      </p>
                      <label for="date" class="form-control-label">
                        *Fecha de baja
                      </label>
                      <div class="row mb-3">
                        <div class="col-12">
                          <el-date-picker
                              v-model="formModel.date"
                              type="date"
                              value-format="YYYY-MM-DD"
                              placeholder="Seleccione una fecha"
                              :disabled-date="disabledDate"
                              :firstDayOfWeek="1"
                              :class="v.date.$errors.length >0 ? 'is-invalid' : ''"
                          />
                        </div>
                      </div>

                      <p class="date-text">(La fecha de baja será el último día del mes en curso. En caso de fallecimiento la baja puede ser retroactiva. Para solicitar la baja debe mandar un email a las personas de contacto adjuntando el certificado de defunción.)</p>
                      <p v-if="v.date.$error" class="invalid-feedback mb-0">
                        <small>
                          {{ v.date.$errors[0].$message }}
                        </small>
                      </p>
                    </div>
                    <div class="mt-3">
                      <button
                        type="submit"
                        class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border border-primary
                      hover:bg-blue-900
                    "
                      >
                        Enviar
                      </button>
                      <button
                        class="       me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900"
                        @click="handleCancel"
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
            <Loading v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, computed} from "vue";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import { useStore } from "vuex";
import axios from '@/services/api';
import router from "../../router";
import Swal from 'sweetalert2';
import Loading from "@/components/Loading.vue";

const formModel = ref({
  company: "",
  name: "",
  first: "",
  second: "",
  date: "",
  number: "",
  document: "",
  motive: ""
});
const isAsisa = computed(() => {
  if(!formModel.value) return false
  return formModel.value.company.includes('Asisa')
});
const handleCancel = () => {
  router.push({ name: "Listado-Mutualistas", params: {} });
};
const rules = computed(() => {
  let ru =  {
    name: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    first: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    second: {},
    company: {},
    date: {
      required: helpers.withMessage("Este campo es obligatorio", required)
    },
    document: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    number: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    motive: {
      //required: helpers.withMessage("Este campo es obligatorio", required),
    },
  };
  if(isAsisa.value){
    ru.motive = {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  }
  return ru;
});
const store = useStore();
const v$ = useVuelidate(rules, formModel);
const v = v$.value;
const loading = ref(false)

const handleSubmit = () => {
  v$.value.$validate();
  if (!v$.value.$invalid) {
    createEntity(formModel.value);
  }
};
const disabledDate = (date) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Get the last day of the current month
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();

  // Check if the given date is less than the last day of the current month
  return (date.getDate() < lastDayOfMonth+1) && date.getMonth() <= currentDate.getMonth();
}
const createEntity = async (data) => {
  loading.value = true
  await axios.post('/mutualist/un-register', data).then(res => {
  if(res.status && (res.status === 200 || res.status === 201)){
    loading.value = false
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Hemos enviado su solicitud',
      showConfirmButton: false,
      timer: 3000
    })

  //  router.go()
  }else{
    loading.value = false
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Ha ocurrido un error',
      showConfirmButton: false,
      timer: 3000
    })
  }
  }).catch(() =>{
    loading.value = false
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Ha ocurrido un error',
      showConfirmButton: false,
      timer: 3000
    })
  })
};

</script>
