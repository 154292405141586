
export default () => ({
    loading: true,
    submitted: false,
    motives: [
        {
            '@id': 'Se va al extranjero',
            'name': 'Se va al extranjero'
        },
        {
            '@id': 'Vuelta a su país de origen',
            'name': 'Vuelta a su país de origen'
        },
        {
            '@id': 'Cambio de compañía',
            'name': 'Cambio de compañía'
        },
        {
            '@id': 'Defunción',
            'name': 'Defunción'
        },
        {
            '@id': 'Se marcha a la seguridad social',
            'name': 'Se marcha a la seguridad social'
        }
    ],
    companies: [
        {
            '@id': "Asisa General",
            'name': 'Asisa General'
        },
        {
            '@id': 'Asisa Misionero',
            'name': 'Asisa Misionero'
        },
        {
            '@id': 'Sanitas',
            'name': 'Sanitas'
        },
        {
            '@id': 'Sanitas Vocare',
            'name': 'Sanitas Vocare'
        },
        {
            '@id': 'Adeslas',
            'name': 'Adeslas'
        },
        {
            '@id': 'IMQ País Vasco',
            'name': 'IMQ País Vasco'
        },
        {
            '@id': 'IMQ Navarra',
            'name': 'IMQ Navarra'
        },
        {
            '@id': 'IMQ Asturias',
            'name': 'IMQ Asturias'
        }

    ],
    documents: [
        {
            '@id': 'DNI',
            'name': 'DNI'
        },
        {
            '@id': 'NIE',
            'name': 'NIE'
        },
        {
            '@id': 'Pasaporte',
            'name': 'Pasaporte'
        }
    ]
})
