<template>
  <div v-if="currentUser == null">Loading</div>

  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <div>
              <h3 class="card-title text-primary mb-0">Editar usuario</h3>
              <div class="sub-line"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="col-sm mx-60">
                <UserUpdateForm :entity="currentUser" action="update" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import UserUpdateForm from "./UserUpdateForm";

const store = useStore();
const route = useRoute();


    const getCurrentUser = async (id) => {
      return await store.dispatch("user/getCurrentUser", id);
    };

    onMounted(getCurrentUser(route.params.id));

    const currentUser = computed(() => store.getters["user/getCurrentUser"]);


</script>
