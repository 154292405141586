export default () => ({
    loading: true,
    users : [],
    currentUser : null,
    errorMessage: null,
    user: null,
    pagination: {
        page: 1,
        totalItems: null,
        first: null,
        last: null,
        next: null
    },
    paginationAccess: {
        page: 1,
        totalItems: null,
        first: null,
        last: null,
        next: null
    }
})