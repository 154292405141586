<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div>
              <h3 class="card-title text-primary mb-0">Solicitud de alta</h3>
              <div class="sub-line"></div>
            </div>
          </div>
          <div class="card-body pb-2">
            <div class="" v-if="!loading">
              <div class="col-sm mx-60">
                <form @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col-md-6">
                      <p class="text-sm text-danger text-bold">Los campos con * son obligatorios</p>
                      <label for="company" class="form-control-label w-100">
                        *Compañía
                      </label>
                      <el-select v-model="formModel.company"
                        size="large" class="w-100">
                        <el-option
                          v-for="selectItem in store.state.mutualist.companies"
                          :key="selectItem['@id']"
                          :value="selectItem['@id']"
                          :label="selectItem.name"
                        />
                      </el-select>
                      <label v-if="hasFiles" class="p-2">
                        Para darse de alta en el {{ formModel.company }}, es necesario rellenar la solicitud de seguro y el cuestionario de salud y enviarlo por email a las siguientes personas o mandando un email a la dirección <a href="mailto:salud@umas.es">salud@umas.es</a>:
                        <contacts />
                      </label>
                      <label v-if="isVocare" class="p-2">
                        Para darse de alta en Sanitas Vocare, es necesario rellenar la solicitud de seguro y el cuestionario de salud y enviarlo por email a las personas de contacto o al email salud@umas.es. En dicho email deberá indicar la ciudad y país de residencia de la persona a asegurar.
                         <contacts />
                        Además, el límite de edad de contratación es 75 años.
                      </label>
                      <div v-if="!hasFiles && !isVocare">
                      <label for="date" class="form-control-label">
                        *Fecha solicitada alta
                      </label>
                        <div class="row mb-3">
                          <div class="col-12">
                            <el-date-picker
                                v-model="formModel.date"
                                type="date"
                                value-format="YYYY-MM-DD"
                                placeholder="Seleccione una fecha"
                                :disabled-date="disabledDate"
                                :firstDayOfWeek="1"
                                :class="v.date.$errors.length >0 ? 'is-invalid' : ''"
                            />
                          </div>
                        </div>
                      <p v-if="v.date.$error" class="invalid-feedback mb-0">
                        <small> {{ v.date.$errors[0].$message }} </small>
                      </p>

                        <label for="name" class="form-control-label">
                          *Nombre
                        </label>
                        <input id="name" v-model="formModel.name"
                          :class="[ 'form-control', v.name.$error ? 'is-invalid' : '',]"
                          type="text"
                          placeholder=""
                        />
                        <p v-if="v.name.$error" class="invalid-feedback mb-0">
                          <small> {{ v.name.$errors[0].$message }} </small>
                        </p>

                      <label for="first" class="form-control-label">
                        *Apellido 1
                      </label>
                      <input id="first" v-model="formModel.first"
                        :class="['form-control', v.first.$error ? 'is-invalid' : '',]"
                        type="text"
                        placeholder=""
                      />
                      <p v-if="v.first.$error" class="invalid-feedback mb-0">
                        <small> {{ v.first.$errors[0].$message }} </small>
                      </p>
                      <label for="second" class="form-control-label">
                        Apellido 2
                      </label>
                      <input id="second" v-model="formModel.second" class="form-control"
                        type="text"
                        placeholder=""
                      />
                      <label for="document" class="form-control-label w-100">
                        *Tipo de documento
                      </label>
                      <el-select v-model="formModel.document" size="large"  class="w-100">
                        <el-option
                          v-for="selectItem in store.state.mutualist.documents"
                          :key="selectItem['@id']"
                          :value="selectItem['@id']"
                          :label="selectItem.name"
                        />
                      </el-select>
                      <label for="number" class="form-control-label">
                        *Número de documento
                      </label>
                      <input
                        id="number"
                        v-model="formModel.number"
                        :class="[
                        'form-control',
                        v.number.$error ? 'is-invalid' : '',
                      ]"
                        type="text"
                        placeholder=""
                      />
                      <p v-if="v.number.$error" class="invalid-feedback mb-0">
                        <small> {{ v.number.$errors[0].$message }} </small>
                      </p>
                      <label for="sex" class="form-control-label w-100">
                          *Sexo
                        </label>
                      <el-select v-model="formModel.sex" size="large"
                          class="w-100"
                        >
                          <el-option value="Femenino" label="Femenino"/>
                          <el-option value="Masculino" label="Masculino" />
                        </el-select>
                      <p v-if="v.sex.$error" class="invalid-feedback mb-0">
                        <small> {{ v.sex.$errors[0].$message }} </small>
                      </p>
                      <label for="birthday" class="form-control-label">
                         *Fecha de nacimiento
                        </label>
                      <input
                          id="birthday"
                          v-model="formModel.birthday"
                          :class="[
                            'form-control',
                            v.birthday.$error ? 'is-invalid' : '',
                            ]"
                          type="date"
                          max="2006-12-31"
                        />
                      <p v-if="v.birthday.$error" class="invalid-feedback mb-0">
                          <small>{{ v.birthday.$errors[0].$message }} </small>
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="!hasFiles && !isVocare">
                        <label for="collective" class="form-control-label">
                          Colectiva
                        </label>
                        <input
                          id="collective"
                          v-model="formModel.collective"
                          class="form-control"
                          type="text"
                          placeholder="Número 5 dígitos"
                        />
                        <label for="viaType" class="form-control-label">
                          *Tipo de via
                        </label>
                        <input
                          id="viaType"
                          v-model="formModel.viaType"
                          :class="[
                        'form-control',
                        v.viaType.$error ? 'is-invalid' : '',
                      ]"
                          type="text"
                          placeholder=""
                        />
                        <p v-if="v.viaType.$error" class="invalid-feedback mb-0">
                          <small> {{ v.viaType.$errors[0].$message }} </small>
                        </p>
                        <label for="viaName" class="form-control-label">
                          *Nombre de la via
                        </label>
                        <input
                          id="viaName"
                          v-model="formModel.viaName"
                          :class="[
                        'form-control',
                        v.viaName.$error ? 'is-invalid' : '',
                      ]"
                          type="text"
                          placeholder=""
                        />
                        <p v-if="v.viaName.$error" class="invalid-feedback mb-0">
                          <small> {{ v.viaName.$errors[0].$message }} </small>
                        </p>
                        <label for="viaNumber" class="form-control-label">
                          *Numero
                        </label>
                        <input
                          id="viaNumber"
                          v-model="formModel.viaNumber"
                          :class="[
                        'form-control',
                        v.viaNumber.$error ? 'is-invalid' : '',
                      ]"
                          type="text"
                          placeholder=""
                        />
                        <p v-if="v.viaNumber.$error" class="invalid-feedback mb-0">
                          <small>
                            {{ v.viaNumber.$errors[0].$message }}
                          </small>
                        </p>
                      <label for="locality" class="form-control-label">
                        *Localidad
                      </label>
                      <input id="locality" v-model="formModel.locality"
                             :class="['form-control', v.locality.$error ? 'is-invalid' : '',]"
                             type="text"
                             placeholder=""
                      />
                      <p v-if="v.locality.$error" class="invalid-feedback mb-0">
                        <small> {{ v.locality.$errors[0].$message }} </small>
                      </p>
                      <label for="province" class="form-control-label">
                        *Provincia
                      </label>
                      <input
                          id="province"
                          v-model="formModel.province"
                          :class="[
                        'form-control',
                        v.province.$error ? 'is-invalid' : '',
                      ]"
                          type="text"
                          placeholder=""
                      />
                      <p v-if="v.province.$error" class="invalid-feedback mb-0">
                        <small> {{ v.province.$errors[0].$message }} </small>
                      </p>
                        <label for="complement" class="form-control-label">
                          Información adicional sobre la dirección
                        </label>
                        <input
                          id="complement"
                          v-model="formModel.complement"
                          class="form-control"
                          type="text"
                          placeholder=""
                        />
                        <label for="zipCode" class="form-control-label">
                          *Código postal
                        </label>
                        <input
                          id="zipCode"
                          v-model="formModel.zipCode"
                          :class="[
                        'form-control',
                        v.zipCode.$error ? 'is-invalid' : '',
                      ]"
                          type="text"
                          placeholder=""
                        />
                        <p v-if="v.zipCode.$error" class="invalid-feedback mb-0">
                          <small>
                            {{ v.zipCode.$errors[0].$message }}
                          </small>
                        </p>
                        <label for="phone" class="form-control-label">
                          Teléfono fijo
                        </label>
                        <input
                          id="phone"
                          v-model="formModel.phone"
                          class="form-control"
                          type="text"
                          placeholder=""
                        />
                        <label for="mobile" class="form-control-label">
                          Teléfono móvil
                        </label>
                        <input
                          id="mobile"
                          v-model="formModel.mobile"
                          class="form-control"
                          type="text"
                          placeholder=""
                        />
                        <label for="email" class="form-control-label">
                          *Email
                        </label>
                        <input
                          id="email"
                          v-model="formModel.email"
                          :class="[
                        'form-control',
                        v.email.$error ? 'is-invalid' : '',
                      ]"
                          type="text"
                          placeholder=""
                        />
                        <p v-if="v.email.$error" class="invalid-feedback mb-0">
                          <small>
                            {{ v.email.$errors[0].$message }}
                          </small>
                        </p>
                      <div v-if="hasAccount">
                        <label for="account" class="form-control-label">
                          Numero de cuenta para los copagos
                        </label>
                        <input
                          id="account"
                          v-model="formModel.account"
                          :class="[
                        'form-control',
                        hasAccount && !isValidAccount ? 'is-invalid' : '',
                      ]"
                          type="text"
                          placeholder=""
                        />
                        <p v-if="hasAccount && !isValidAccount" class="invalid-feedback mb-0">
                          <small>
                            Este campo es obligatorio
                          </small>
                        </p>
                      </div>
                      </div>

                  </div>
                  <div class="row documents" v-if="hasFiles">
                    <label>Documentos para descargar</label>
                    <a target="_blank" v-if="formModel.company === 'IMQ Asturias'" href="/files/IMQ_Cuestionario_de_Salud_UMAS.pdf" class="col-12">
                      <img alt="pdf-icon" class="img-pdf" src="@/assets/img/pdf.png"> Cuestionario de salud
                    </a>
                    <a target="_blank" v-if="formModel.company === 'IMQ Asturias'" href="/files/IMQ_asturias_Solicitud_Seguro.pdf" class="col-12">
                      <img alt="pdf-icon" class="img-pdf" src="@/assets/img/pdf.png"> Solicitud de seguro
                    </a>
                    <a target="_blank" v-if="formModel.company === 'IMQ País Vasco'" href="/files/solicitud _cuestionario_orden_sepa_IMQ_PV.pdf" class="col-12">
                      <img alt="pdf-icon" class="img-pdf" src="@/assets/img/pdf.png"> Solicitud de seguro
                    </a>
                    <!--************* Navarra ************* -->
                    <a target="_blank" v-if="formModel.company === 'IMQ Navarra'" href="/files/cuestionario_navarra.pdf" class="col-12">
                        <img alt="pdf-icon" class="img-pdf" src="@/assets/img/pdf.png"> Cuestionario de salud
                      </a>
                    <a target="_blank" v-if="formModel.company === 'IMQ Navarra'" href="/files/solicitud_navarra.pdf" class="col-12">
                      <img alt="pdf-icon" class="img-pdf" src="@/assets/img/pdf.png"> Solicitud de seguro
                    </a>
                    <!-- ************** End Navarra ********** -->
                  </div>
                  <div class="row documents" v-if="isVocare">
                    <!-- ************** Sanitas Vocare ********** -->
                    <a target="_blank"  href="/files/solicitud_sanitas_vocare.pdf" class="col-12">
                      <img alt="pdf-icon" class="img-pdf" src="@/assets/img/pdf.png"> Solicitud de seguro
                    </a>
                    <!-- ************** End Sanitas Vocare ********** -->
                  </div>


                  <div class="mt-3" v-if="!hasFiles && !isVocare">
                    <input type="checkbox" required v-model="formModel.accepted"/>
                    <label for="account" class="form-control-label">
                      <span style="color:grey;"> He leído y aceptado la</span> <b @click="showPolicies=true" class="cursor-pointer underline">Política de Privacidad</b>
                    </label>

                        <button type="submit" class="float-end btn btn-primary text-white
                      bg-blue-600
                      rounded-lg
                      border border-primary
                      hover:bg-blue-900">
                          Enviar
                        </button>
                        <button  class="me-2 float-end btn btn-primary text-danger bg-white border border-danger rounded-lg hover:bg-blue-900"
                                 @click="handleCancel">
                          Cancelar
                        </button>
                      </div>


                </form>
              </div>
              <el-dialog style="width: 50%;" class="dd" v-model="showPolicies" title="">
                <policies />
              </el-dialog>
            </div>
            <Loading v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, computed} from "vue";
import useVuelidate from "@vuelidate/core";
import {helpers, required, email} from "@vuelidate/validators";
import { useStore } from "vuex";
import router from "../../router";
import axios from '@/services/api';
import Swal from 'sweetalert2';
import Contacts from "@/components/Contacts.vue";
import Loading from "@/components/Loading.vue";
import Policies from "@/views/terms/Policies.vue";

const loading = ref(false)
const isValidAccount = ref(true)
const formModel = ref({
  company: "",
  name: "",
  first: "",
  second: "",
  date: "",
  phone: "",
  mobile: "",
  complement: "",
  locality: "",
  province: "",
  account: null,
  viaType: "",
  viaName: "",
  viaNumber: "",
  sex: "",
  number: "",
  document: "",
  birthday: "",
  collective: "",
  accepted: false
});
const firstDay = (value) => {
  return parseInt(value.split('T')[0].split('-')[1]) !== 1;

};
const disabledDate = (date) => {
  const currentDate = new Date();
 // const currentMonth = currentDate.getMonth();
//  const currentYear = currentDate.getFullYear();

  // Get the last day of the current month
 // const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();

  // Check if the given date is less than the last day of the current month
  return (date.getDate() !== 1 || date.getMonth() < currentDate.getMonth());
}
const showPolicies = ref(false)
const rules = computed(() => {
  return {
    company: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    name: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    first: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    number: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    email: {
      email: helpers.withMessage("Debe ser una dirección de email válida", email),
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    locality: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    province: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    viaNumber: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    viaType: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    viaName: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    zipCode: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    date: {
      required: helpers.withMessage("Este campo es obligatorio", required),
      firstDay: helpers.withMessage("Debe ser el primer dia del mes", firstDay),
    },
    sex: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    document: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    birthday: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  };
});
const store = useStore();
const v$ = useVuelidate(rules, formModel);
const v = v$.value;

const handleSubmit = () => {
  if(!formModel.value.account){
    isValidAccount.value= false
  }
  v$.value.$validate();
  if (!v$.value.$invalid) {
    createEntity(formModel.value);
  }
};
const handleCancel = () => {
  router.push({ name: "Listado-Mutualistas", params: {} });
};
const hasFiles = computed(() => {
  if(!formModel.value) return false
  return ['IMQ País Vasco','IMQ Navarra','IMQ Asturias'].includes(formModel.value.company)
});
const hasAccount = computed(() => {
  if(!formModel.value) return false
  return ['IMQ País Vasco','IMQ Navarra','IMQ Asturias','Sanitas'].includes(formModel.value.company)
});
const isVocare = computed(() => {
  if(!formModel.value) return false
  return 'Sanitas Vocare' === formModel.value.company
});

const createEntity = async (data) => {
  loading.value = true
  await axios.post('/mutualist/register', data).then(res => {
    if(res.status && (res.status === 200 || res.status === 201)){
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Hemos enviado su solicitud',
        showConfirmButton: false,
        timer: 3000
      })
      loading.value = false
    //  router.go()
    }else{
      loading.value = false
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Ha ocurrido un error',
        showConfirmButton: false,
        timer: 3000
      })
    }
  }).catch(() =>{
    loading.value = false
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Ha ocurrido un error',
      showConfirmButton: false,
      timer: 3000
    })
  })

};
/*const today = computed(() => {
  return new Date().toISOString().split('T')[0];
})*/
</script>
<style lang="scss" scoped >

.documents{
  a{
    font-weight: bold;
    text-decoration: underline;
  }
}


</style>
