export const getUsers = (state) => {
    return state.users.filter(user => !user.roles.includes('ROLE_SUPER_ADMIN'));
}

export const getCurrentUser = (state) => {
    return state.currentUser
}

export const getUser = (state) => {
    if(state.user) return state.user
    if(sessionStorage.getItem('user')){
        state.user = JSON.parse(sessionStorage.getItem('user'))
    }
    return state.user
}


export const getErrorMessage = (state) => {
    return state.errorMessage
}