<template>
  <div class="card mb-4">
    <div class="card-header pb-3">
      <div>
        <h3 class="card-title text-primary mb-0">Política de Privacidad</h3>
        <div class="sub-line"></div>
      </div>
    </div>
    <div class="card-body px-3 pt-0 pb-2">
      <p>En UMAS Mutua de Seguros nos preocupa asegurar la confidencialidad de los datos personales de nuestros Mutualistas, por ello queremos informar de la política de protección de datos que tenemos implantada.</p>
      <p>Desde el día 25 de mayo de 2018 es plenamente aplicable en toda la Unión Europea el Reglamento Europeo de Protección de Datos. En este sentido queremos informarle del tratamiento de datos personales que realizamos en UMAS, como lo hacemos, con que finalidad y que derechos tiene al respecto.</p>

      <h4>INFORMACIÓN ADICIONAL SOBRE PROTECCIÓN DE DATOS</h4>
      <h5>Responsable del tratamiento de sus datos personales</h5>
      <p><b>Identidad:</b> UMAS Unión Mutua Asistencial de Seguros y Reaseguros a Prima Fija.</p>

      <p><b>CIF:</b> G-28747574</p>
      <p><b>Domicilio:</b>  C/ Santa Engracia, nº 10-12, 28010, Madrid.</p>
      <p><b>Correo electrónico:</b>  <a class="underline" href="mailto:lopd@umas.es">lopd@umas.es</a></p>
      <p><b>Contacto Delegado de Protección de Datos (DPD):</b> <a class="underline" href="mailto: dpd@umas.es"> dpd@umas.es</a></p>

      <h4>Obtención de Datos</h4>
      <p>UMAS ha obtenido los datos personales que serán objeto de tratamiento de alguna de las siguientes fuentes:</p>
      <p>Facilitados voluntariamente por usted en el momento de la formalización de la relación contractual, para la elaboración del proyecto solicitado y/o la contratación del seguro, que serán complementados con los obtenidos a lo largo de la duración del contrato con los que se generen como consecuencia de su ejecución.
        A través de la información que conste en ficheros comunes creados por las entidades aseguradoras con habilitación legal a los que UMAS esté adherida.
        De registros públicos y/o fuentes accesibles al público si fuera necesario y siempre que exista interés legítimo para ello, y sólo en los casos de contratación.
        En el caso de proporcionarnos información sobre otras personas debe obtener su consentimiento. Esto es aplicable a todos los datos, y especialmente, si los hubiera, a datos sensibles como lo relativos a la salud y/o sanciones administrativas. Si nos proporciona datos de otras personas nos está confirmando que tiene su permiso y que estas personas han comprendido como se utilizará dicha información.</p>
      <h4>Finalidad del tratamiento de sus datos</h4>
      <p>En caso de que lleve a cabo una <b>contratación</b> de seguros, sus datos personales serán tratados para las finalidades que se detallan a continuación:</p>
      <ul>
        <li>Formalización y gestión del proyecto y/o contrato de seguro solicitado, con el fin de dar cumplimiento a las obligaciones, prestaciones, coberturas y servicios derivados del contrato suscrito. Así mismo, podrán ser tratados datos de salud cuando ello sea necesario para la gestión y tramitación de las coberturas del contrato suscrito.</li>
        <li>Realizar controles de calidad en relación a los servicios prestados respecto al seguro contratado.</li>
        <li>Cumplir con la normativa sectorial que afecta a UMAS en función de su actividad aseguradora y en atención a los servicios prestados, especialmente la normativa de seguros, de distribución, de prevención de blanqueo de capitales cuando proceda y prevención e investigación del fraude.</li>
        <li>Para el ejercicio o la defensa de las reclamaciones de cualquier tipo que pudieran derivarse de la relación mantenida.</li>
        <li>Para remitirle información publicitaria comercial y/o promocional de productos de seguros y/o servicios de UMAS, a través de cualquier medio, incluidos los electrónicos (en particular por sms o mail), e incluso una vez finalizada la relación contractual (si usted así lo ha autorizado).</li>
        <li>En el caso de que lleve a cabo una <b>tarificación</b>, sus datos personales serán tratados para las finalidades que se detallan a continuación:</li>
        <li>Formalización y gestión del proyecto de seguro solicitado.</li>
        <li>Cumplir con la normativa sectorial que afecta a Umas en función de su actividad aseguradora, especialmente la normativa de seguros y de distribución.</li>
        <li>Para el ejercicio o la defensa de las reclamaciones de cualquier tipo que pudieran derivarse de la relación mantenida.</li>
        <li>Si usted lo ha autorizado, para remitirle un nuevo proyecto de seguro en los 12 meses siguientes a su plazo de validez, a través de cualquier medio incluidos los electrónicos (en particular por sms o mail).</li>
      </ul>
      <h4>Tiempo de conservación de sus datos</h4>
      <p>En virtud de la relación jurídica como consecuencia de la <b>contratación del seguro</b>, los datos personales serán conservados:</p>
      <ul>
        <li>Durante toda la vigencia de la relación contractual, así como, tras su finalización cuando el tratamiento de los datos sea necesario para atender las posibles responsabilidades o reclamaciones que pudieran derivarse de la relación mantenida.</li>
        <li>Posteriormente, durante los plazos legales que resulten de aplicación en cada caso concreto, en función de la finalidad para la que fueron recabados, así como para cumplir con los requerimientos y responsabilidades de la normativa vigente aplicable en cada caso, en concreto los plazos establecidos en: Ley de Contrato de Seguro, Ley de ordenación, supervisión y solvencia de las entidades aseguradoras y reaseguradoras, Ley sobre responsabilidad civil y seguro en la circulación de vehículos a motor (aplicable solo en los seguros de automóviles), normativa de distribución, Ley de prevención y blanqueo de capitales (cuando proceda), así como cualquier otra que fuera de aplicación.</li>
      </ul>
      <p>No obstante, los datos permanecerán en situación de bloqueo durante los plazos que determina la normativa especificada anteriormente, a fin de poder atender las responsabilidades que se pudieran generar. Transcurridos dichos plazos se procederá a su supresión, salvo que el interesado haya autorizado su tratamiento para finalidades concretas por un plazo superior.</p>
      <p>En virtud de la relación jurídica como consecuencia de la <b>tarificación</b> efectuada, los datos personales serán conservados:</p>
      <ul>
        <li>Durante toda la vigencia del Proyecto, procediéndose a su cancelación tras diez días desde la finalización de su validez si no se llegara a suscribir el contrato de seguro, todo ello de conformidad con lo establecido en la Ley de ordenación, supervisión y solvencia de las entidades aseguradoras y reaseguradoras y la normativa de distribución.</li>
        <li>Posteriormente, si nos ha autorizado a remitirle un nuevo proyecto de seguro los datos se mantendrán durante los 12 meses siguientes a su plazo de validez, procediéndose posteriormente a su cancelación y supresión.</li>
      </ul>
      <h4> Seguridad de sus datos</h4>
      <p>Desde el comienzo del tratamiento se aplicarán las medidas organizativas y de seguridad para garantizar la integridad, confidencialidad, disponibilidad, resiliencia o invulnerabilidad, para evitar la pérdida, mal uso y acceso no autorizado a sus datos personales. Todo ello conforme a lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales, así como lo que se establezca en la normativa nacional que sea de aplicación.</p>
      <h4>Legitimación para el tratamiento de sus datos</h4>
      <p> La base legal para el tratamiento de sus datos se encuentra amparada en:</p>
      <ul>
        <li>La formalización, ejecución y gestión del proyecto y/o contrato de seguro solicitado (incluidos los datos de salud en el caso de que fueran necesarios), en los términos que constan detallados en la información legal del Proyecto y/o en las condiciones particulares de la que forma parte esta información. Dichos datos serán necesarios, por lo que la negativa a facilitarlos conlleva la imposibilidad de <b>tarificación</b> y/o contratación, así como de la prestación de las coberturas contratadas.</li>
        <li>El cumplimiento de la normativa de seguros y obligaciones que afectan a UMAS, especialmente lo determinado en la Ley de Contrato de Seguro, la Ley de ordenación, supervisión y solvencia de las entidades aseguradoras y reaseguradoras, Ley sobre responsabilidad civil y seguro en la circulación de vehículos a motor (aplicable sólo en los seguros de automóviles), normativa de distribución de seguros, Ley de prevención y blanqueo de capitales (cuando proceda).</li>
        <li>El interés legítimo que ampara finalidades tales como: realizar un análisis para la valoración, selección y delimitación del riesgo y la prevención del fraude en base a los datos facilitados y aquellos obtenidos de ficheros comunes de siniestralidad del sector asegurador a los que Umas esté adherida, realización de encuestas o controles de calidad en relación a los servicios prestados, elaboración de perfiles con fines actuariales y de análisis de mercado, cesión a las entidades por razón de reaseguro o coaseguro, envío de comunicaciones comerciales por UMAS.</li>
        <li>El consentimiento expreso si ha autorizado el envío de un nuevo Proyecto en los doce meses siguientes, el envío de comunicaciones comerciales sobre otros productos y servicios que se adapten a su perfil, así como la posibilidad de su remisión una vez finalizada la relación contractual.</li>
      </ul>
      <h4>Comunicación de sus datos a terceros</h4>
      <p>Sus datos serán tratados de forma confidencial de acuerdo con las finalidades y objeto del contrato, tanto por Umas como por su red de mediadores, así como por otras entidades que, por cuestiones de reaseguro y coaseguro intervengan en la gestión del seguro contratado, por terceros colaboradores o proveedores para la gestión de las prestaciones derivadas del seguro contratado. Sus datos podrán ser comunicados a la Dirección General de Seguros y a otras Autoridades y Órganos Administrativos y Judiciales en función de sus competencias, cuando estos así lo requieran o dicha comunicación sea necesaria para salvaguardar los derechos o intereses legítimos de Umas.</p>
      <h4>Derechos que le asisten respecto a los datos facilitados</h4>
      <p>El interesado en relación a los datos que son tratados por Umas, tiene la posibilidad de ejercitar de forma libre y gratuita los siguientes derechos:</p>
      <ul>
        <li>Acceder a sus datos personales.</li>
        <li> Rectificar sus datos personales cuando resulten inexactos o incompletos.</li>
        <li>Suprimir sus datos personales cuando no sean necesarios para los fines para los que fueron recabados, entre otros motivos.</li>
        <li>Limitar el tratamiento por parte de Umas de todos o parte de sus datos personales en las circunstancias que determina la ley.</li>
        <li>Oponerse a determinados tratamientos en las circunstancias y por motivos relacionados con su situación particular.</li>
        <li>Solicitar la portabilidad de sus datos personales en un formato interoperable y autosuficiente.</li>
        <li> Retirar, en cualquier momento, los consentimientos otorgados previamente.</li>
      </ul>
      <p>De conformidad con la normativa vigente, el interesado puede ejercer sus derechos solicitándolo por escrito, y junto a una copia de un documento fehaciente que acredite su identidad, en la siguiente dirección postal: C/ Santa Engracia, nº 10-12, 28010, Madrid o en el correo electrónico <a class="underline" href="mailto:lopd@umas.es">lopd@umas.es</a></p>
      <p>Igualmente, en el caso de no haya obtenido satisfacción en el ejercicio de sus derechos podrá interponer una reclamación ante la Agencia Española de Protección de Datos, autoridad de control en materia de protección de datos, en la siguiente dirección: C/ Jorge Juan, 6, 28001.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Policies"
}
</script>

<style scoped>

</style>